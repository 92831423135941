
import { SaveResult } from '@/types';
import { State, Getter } from 'vuex-class';
import { mixins } from "vue-class-component";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient } from '@/store/recipients/types';
import ModalSection from '@/components/shared/ModalSection.vue';
import { JourneyStage, JourneyStatus, PostTransplantFollowUp, } from '@/store/recipientJourney/types';
import { RecipientJourney, RecipientStageAttributes } from '@/store/recipientJourney/types';

import CardSection from '@/components/shared/CardSection.vue';
import TransplantDetails from '@/components/organs/shared/TransplantDetails.vue';
import { OrganCodeValue } from '@/store/lookups/types';
import { TransplantDetailsPageState, TransplantSectionPageState } from '@/mixins/transplant-mixin';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

@Component({
  components: {
    CardSection,
    ModalSection,
    TransplantDetails,
  }
})
export default class TransplantDetailsSection extends mixins(TranslationUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.transplantSection) editState!: TransplantSectionPageState;
  @State(state => state.journeyState.postTransplantFollowUps) private postTransplantFollowUps!: PostTransplantFollowUp[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter("isOopRecipient", { namespace: 'recipients' }) isOopRecipient!: boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed || this.journey.organ_code == OrganCodeValue.PancreasIslets) {
      return false;
    }
    return true;
  }

  // Whether or not the selected journey has a Transplant Date already
  get hasTransplantDate(): boolean {
    if (!this.journey) return false;

    return !!this.journey?.stage_attributes?.transplant?.factors?.transplant_date;
  }

  /**
   * Whether or not the Transplant has been finalized
   *
   * Note: here we assume that if journey is in Post-Transplant stage and
   * has at least one Follow-Up then we should prevent Clear Transplant Data.
   */
  get isTransplantFinal(): boolean {
    if (!this.journey) return false;

    return this?.journey?.stage === JourneyStage.PostTransplant && (this.postTransplantFollowUps?.length || []) > 0;
  }

  /**
   * Whether or not user can use the Clear Transplant Data button
   *
   * This checks user permissions and whether or not the journey is still in the
   * 'non-finalized' transplanted state (i.e. before post-transplant follow-ups).
   *
   * Note: we only have 'CRU' access booleans, not 'CRUD'. In other words, our
   * role-based permissions framework distinguishes Create / Read / Update but
   * not Delete. Therefore, here we assume that Write permission (C & U) can be
   * used to determine if the user is authorized to Delete the Transplant Data.
   */
  get canClearTransplantData(): boolean {
    if(this.journey.organ_code == OrganCodeValue.PancreasIslets) return false;
    return !this.isTransplantFinal && this.isGroupWriteable('journey_transplant_details');
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const result = {};

    // Transplant Details
    const transplantDetails = this.$refs.transplantDetails as TransplantDetails;
    if (transplantDetails) {
      Object.assign(result, { ...transplantDetails.idLookup() });
    }

    return result;
  }

  /**
   * Populates form state with default values for the Referral Section
   */
  public initializeForm(): void {
    // Initialize empty page state
    let transplantDetailsPageState: TransplantDetailsPageState = { organTransplantDetails: {} };
    // Gather stage attributes used to build this section
    const stageAttributes: RecipientStageAttributes = !!this.journey ? this.journey.stage_attributes || {} : {};

    if (!this.newJourney && !!stageAttributes) {
      const transplantDetailsForm = this.$refs.transplantDetails as TransplantDetails;
      // Build page stage for Transplant, delegate building page states to the subsection
      if (!!transplantDetailsForm) {
        const recipientStatus =  stageAttributes.transplant?.factors?.transplant_date ? JourneyStatus.Transplanted : ( (stageAttributes.waitlist?.factors?.transplant_in_progress || stageAttributes.waitlist?.factors?.living_donor_transplant_in_progress) ? JourneyStatus.TransplantInProgress : '');
        transplantDetailsPageState = transplantDetailsForm.buildTransplantDetailsForm(stageAttributes.transplant,recipientStatus);
      } else {
        // this is needed when navigating between different organ transplant forms to prevent unexpected clearing of page state
        return;
      }
    }

    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'transplantSection',
      value: {
        transplantDetails: transplantDetailsPageState,
        isClearingTransplantData: false,
        modalErrorMessages: null,
      },
    });
  }

  public reinitialize(){
    this.reload().then(() => {
      // Only after the reloading has finished, re-initialize the form
      this.initializeForm();
    });
  }

   /**
   * Return an error message to show if there's any
   * uses translateIPOSContext to translate HSP/IPOS text
   * depending on ctrIPOSKidney configuration
   *
   * @returns {string} error message to show
   */
   public getErrorMessage(error: any, field: string|null): string{
    if (typeof error == 'string' && error == 'check_not_ctr_synced') {
      return this.translateIPOSContext(`custom_errors.${field}.${error}`);
    } else {
      return this.translateError(error, field);
    }
  }

  
  // PRIVATE

  // Loading processes unique to this component
  private loaded(): void {
    this.$emit('loaded', 'transplantDetailsSection');
    // Reload the page and Initialize Form
    this.initializeForm();
  }

  // Reload recipient and journey data after saving
  private handleSaved(event: any) {
    this.reload().then(() => {
      this.initializeForm();
    }).catch(() => {
      this.initializeForm();
    });
  }

  /**
   * Reload recipient and journey data, and return a promise so that other
   * actions can be sequenced after this.
   *
   * Note: which actions should be dispatched depends on whether or not the
   * selected recipient is from Out-of-Province.
   *
   * @returns {Promise<void>} promise that resolves when loading completes, or
   * rejects when the recipient data cannot be reloaded.
   */
  private reload(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Check whether we need to load recipient data or OOP recipient data
      const recipients_route = this.isOopRecipient ? 'recipients/getOop' : 'recipients/get';
      if (!this.isOopRecipient) {
        // In-province
        this.reloadRecipientTransplant().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      } else {
        // In-province
        this.reloadOopRecipientTransplant().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      }
    });
  }

  /**
   * Reload recipient, journey, journey durations, waitlist decisions, and then
   * finally select most recent changeable row in waitlist history.
   *
   * Returns a promise so that other actions can be sequenced after this.
   *
   * @returns {Promise<void>} promise that resolves when loading completes, or
   * rejects when the recipient data cannot be reloaded.
   */
  private reloadRecipientTransplant(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // In-province
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          Promise.all([
            this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }),
            this.$store.dispatch('journeyState/getTransplant', { journeyId: this.journeyId, recipientId: this.recipientId }),
            this.$store.dispatch('journeyState/loadWaitlistDecisions', { recipientId: this.recipientId, journeyId: this.journeyId }),
          ]).finally(() => {
            resolve();
          });
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
  }

  /**
   * Reload Out-of-Province recipient and journey transplant data
   *
   * Returns a promise so that other actions can be sequenced after this.
   *
   * @returns {Promise<void>} promise that resolves when loading completes, or
   * rejects when the OOP recipient data cannot be reloaded.
   */
  private reloadOopRecipientTransplant(): Promise<void> {
    // OOP
    return new Promise<void>((resolve, reject) => {
      this.$store.dispatch('recipients/getOop', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('journeyState/getTransplantOop', { journeyId: this.journeyId, recipientId: this.recipientId }).then(() => {
            resolve();
          }).catch(() => {
            reject();
          });
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
  }

  // Reset popup modal errors
  private resetModalSaveErrors(): void {
    if (!this.editState) return;

    // Mutate multiple v-models simultaneously to reduce the number of observed changes
    const value: TransplantSectionPageState = Object.assign({}, this.editState);
    Object.assign(value, {
      isClearingTransplantData: false,
      modalErrorMessages: null,
    });
    this.$store.commit('pageState/set', { pageKey: 'transplantSection', value });
  }

  // Begin popup modal save
  private beginModalSave(): void {
    if (!this.editState) return;

    // Mutate multiple v-models simultaneously to reduce the number of observed changes
    const value: TransplantSectionPageState = Object.assign({}, this.editState);
    Object.assign(value, {
      isClearingTransplantData: true,
      modalErrorMessages: null,
    });
    this.$store.commit('pageState/set', { pageKey: 'transplantSection', value });
  }

  // Show modal save errors
  private showModalSaveErrors(result: SaveResult): void {
    if (!this.editState) return;

    // Mutate multiple v-models simultaneously to reduce the number of observed changes
    const value: TransplantSectionPageState = Object.assign({}, this.editState);
    Object.assign(value, {
      isClearingTransplantData: false,
      modalErrorMessages: result.errorMessages,
    });
    this.$store.commit('pageState/set', { pageKey: 'transplantSection', value });
  }

  // Initiate the Clear Transplant Data functionality by showing confirmation pop-up
  private clearTransplantData(): void {
    // Show modal
    const clearTransplantDataModal = this.$refs.clearTransplantDataModal as ModalSection;
    if (!clearTransplantDataModal) return;

    this.resetModalSaveErrors();
    clearTransplantDataModal.toggleStaticModal();
  }

  // Proceed to Clear Transplant Data after secondary confirmation pop-up
  private confirmClearTransplantDate(): void {
    const clearTransplantDataModal = this.$refs.clearTransplantDataModal as ModalSection;
    // Dispatch action and show response
    this.beginModalSave();
    this.$store.dispatch('journeyState/clearTransplantData', { recipientId: this.recipientId, journeyId: this.journeyId }).then((result: SaveResult) => {
      // Check if the action was successfuly
      if (result.success) {
        // If so, reload all the data that might change as a result of this action
        this.reload().then(() => {
          // Only after the reloading has finished, should we dismiss the modal and re-initialize the form
          this.initializeForm();
          this.resetModalSaveErrors();
          if (clearTransplantDataModal) clearTransplantDataModal.hideModal();
        });
      } else {
        // Otherwise, stop progress indication and show errors
        this.showModalSaveErrors(result);
      }
    }).catch((result: SaveResult) => {
      // Stop progress indication and show errors
      this.showModalSaveErrors(result);
    });
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  private clear(): void {
    this.$emit('clear');
  }
}
