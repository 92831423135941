export const EP: any = {
  recipients: {
    index: '/recipients?page_number=:page_number&page_size=:page_size:search_params',
    indexUrgent: '/recipients?page_number=:page_number&page_size=:page_size&urgent=:urgent:search_params',
    create: '/recipients',
    show: '/recipients/:id',
    update: '/recipients/:id',
    measurements: {
      index: '/recipients/:recipientId/measurements',
      create: '/recipients/:recipientId/measurements',
      show: '/recipients/:recipientId/measurements/:id',
      update: '/recipients/:recipientId/measurements/:id',
    },
    hlaTyping: {
      create: '/recipients/:recipientId/hla_typing',
      show: '/recipients/:recipientId/hla_typing',
      update: '/recipients/:recipientId/hla_typing',
    },
    attachments: {
      create: '/recipients/:recipientId/attachments',
      show: '/recipients/:recipientId/attachments/:id',
      update: '/recipients/:recipientId/attachments/:id'
    },
    hlaAntibodies: {
      index: '/recipients/:recipientId/hla_antibodies',
      create: '/recipients/:recipientId/hla_antibodies',
      show: '/recipients/:recipientId/hla_antibodies/:id',
      update: '/recipients/:recipientId/hla_antibodies/:id',
    },
    liverMeldLabs: {
      index: '/recipients/:recipientId/liver_meld_labs',
      create: '/recipients/:recipientId/liver_meld_labs',
      show: '/recipients/:recipientId/liver_meld_labs/:id',
      update: '/recipients/:recipientId/liver_meld_labs/:id',
    },
    liverPeldLabs: {
      index: '/recipients/:recipientId/liver_peld_labs',
      create: '/recipients/:recipientId/liver_peld_labs',
      show: '/recipients/:recipientId/liver_peld_labs/:id',
      update: '/recipients/:recipientId/liver_peld_labs/:id',
    },
    liverHccLabs: {
      index: '/recipients/:recipientId/liver_hcc_labs',
      create: '/recipients/:recipientId/liver_hcc_labs',
      show: '/recipients/:recipientId/liver_hcc_labs/:id',
      update: '/recipients/:recipientId/liver_hcc_labs/:id',
      edit_validations: '/recipients/:recipientId/liver_hcc_labs/:id/edit',
      new_validations: '/recipients/:recipientId/liver_hcc_labs/new',
    },
    death: {
      show: '/recipients/:recipientId/death',
      update: '/recipients/:recipientId/death',
    },
    diagnosticsHla: {
      show: '/recipients/:recipientId/diagnostics_hla',
      update: '/recipients/:recipientId/diagnostics_hla',
    },
    journeys: {
      create: '/recipients/:recipientId/journeys',
      update: '/recipients/:recipientId/journeys/:journeyId',
      referral: {
        update: '/recipients/:recipient_id/journeys/:journey_id/referral',
        referralDetails: '/recipients/:recipientId/journeys/:journeyId/referral_details'
      },
      assessment: {
        update: '/recipients/:recipient_id/journeys/:journey_id/assessment',
        addToWaitlist: '/recipients/:recipientId/journeys/:journeyId/assessment/add_to_waitlist',
      },
      consultation: {
        update: '/recipients/:recipient_id/journeys/:journey_id/consultation'
      },
      waitlist: {
        update: '/recipients/:recipientId/journeys/:journeyId/waitlist_attributes',
        overrideWaitTime: '/recipients/:recipient_id/journeys/:journey_id/waitlist_attributes/override_wait_time',
        removeFromWaitlist: '/recipients/:recipient_id/journeys/:journey_id/waitlist_attributes/remove_from_waitlist',
        hcc_in_criteria: '/recipients/:recipient_id/journeys/:journey_id/waitlist_attributes/hcc_in_criteria',
      },
      durations: {
        index: '/recipients/:recipientId/journeys/:journeyId/durations'
      },
      waitlist_decisions: {
        index: '/recipients/:recipientId/journeys/:journeyId/significant_event_decisions/waitlist_decisions',
      },
      wait_time_override_events: {
        index: '/recipients/:recipientId/journeys/:journeyId/significant_event_decisions/wait_time_override_events',
      },
      transplant: {
        update: '/recipients/:recipientId/journeys/:journeyId/transplant',
        show: '/recipients/:recipientId/journeys/:journeyId/transplant',
        delete: '/recipients/:recipient_id/journeys/:journey_id/transplant',
      },
      cluster: {
        create: '/recipients/:recipientId/journeys/:journeyId/cluster',
        uncluster: '/recipients/:recipientId/journeys/:journeyId/uncluster',
      },
      post_transplant: {
        follow_ups: {
          create: '/recipients/:recipientId/journeys/:journeyId/post_transplant/follow_ups',
          index: '/recipients/:recipientId/journeys/:journeyId/post_transplant/follow_ups',
          update: '/recipients/:recipientId/journeys/:journeyId/post_transplant/follow_ups/:id',
          edit_validations: '/recipients/:recipient_id/journeys/:journey_id/post_transplant/follow_ups/:id/edit',
          new_validations: '/recipients/:recipient_id/journeys/:journey_id/post_transplant/follow_ups/new'
        }
      },
      organ_specific_details: {
        alcoholic_liver_disease_programs: {
          create: '/recipients/:recipientId/journeys/:journeyId/organ_specific_details/alcoholic_liver_disease_programs',
          update: '/recipients/:recipientId/journeys/:journeyId/organ_specific_details/alcoholic_liver_disease_programs/:id',
          index: '/recipients/:recipientId/journeys/:journeyId/organ_specific_details/alcoholic_liver_disease_programs',
        },
        liver_smc_result: {
          update: '/recipients/:recipient_id/journeys/:journey_id/organ_specific_details/liver_smc_result',
        },
        liver_criteria_decisions: {
          index: '/recipients/:recipient_id/journeys/:journey_id/significant_event_decisions/criteria_decisions',
        },
        overridePaediatricAge: {
          update: '/recipients/:recipient_id/journeys/:journey_id/organ_specific_details/override_paediatric_age',
        },
      },
      historyLiverSmcs: {
        index: '/recipients/:recipientId/journeys/:journeyId/history_liver_smcs'
      },
      transferStatuses: {
        activeTransfer: {
          singleToSingle: '/recipients/:recipient_id/journeys/:id/transfer_statuses/active_transfer/single_to_single',
          singleToCluster: '/recipients/:recipient_id/journeys/:id/transfer_statuses/active_transfer/single_to_cluster',
          clusterToCluster: '/recipients/:recipient_id/journeys/:id/transfer_statuses/active_transfer/cluster_to_cluster',
        },
        postTransplant: {
          singleFollowUpTransfer: '/recipients/:recipient_id/journeys/:id/transfer_statuses/post_transplant/single_follow_up_transfer',
          clusterFollowUpTransfer: '/recipients/:recipient_id/journeys/:id/transfer_statuses/post_transplant/cluster_follow_up_transfer',
        },
      },
      decisions: {
        index: "/recipients/:recipientId/journeys/:journeyId/decisions",
        create: "/recipients/:recipientId/journeys/:journeyId/decisions",
        update: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId",
        show: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId",
        new_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/new",
        edit_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/edit",
        attachments: {
          index: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments",
          create: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments",
          update: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments/:attachmentId",
          show: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments/:attachmentId",
          new_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments/new",
          edit_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/attachments/:attachmentId/edit",
        },
        responses: {
          index: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses",
          create: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses",
          update: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses/:responseId",
          show: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses/:responseId",
          new_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses/new",
          edit_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/decision_responses/:responseId/edit",
        },
        finalDecisions: {
          index: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions",
          create: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions",
          update: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions/:finalDecisionId",
          show: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions/:finalDecisionId",
          new_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions/new",
          edit_validations: "/recipients/:recipientId/journeys/:journeyId/decisions/:decisionId/final_decisions/:finalDecisionId/edit",
        }
      }
    },
    outOfProvinceJourneys: {
      index: '/recipients/:recipientId/oop_journeys',
      create: '/recipients/:recipientId/oop_journeys',
      update: '/recipients/:recipientId/oop_journeys/:id',
    },
    significantEventDecisions: {
      index: '/recipients/:recipientId/significant_event_decisions',
    },
    organ_specific_details: {
      update: '/recipients/:recipientId/journeys/:id/organ_specific_details',
    },
    dialysis_procedures: {
      index: '/recipients/:recipientId/dialysis_procedures',
      create: '/recipients/:recipientId/dialysis_procedures',
      update: '/recipients/:recipientId/dialysis_procedures/:id'
    },
    liver_bridging_procedures: {
      index: '/recipients/:recipientId/liver_bridging_procedures',
      create: '/recipients/:recipientId/liver_bridging_procedures',
      update: '/recipients/:recipientId/liver_bridging_procedures/:id'
    },
    liver_downstaging_procedures: {
      index: '/recipients/:recipientId/liver_downstaging_procedures',
      create: '/recipients/:recipientId/liver_downstaging_procedures',
      update: '/recipients/:recipientId/liver_downstaging_procedures/:id'
    },
    heart_measurements: {
      index: '/recipients/:recipientId/heart_measurements',
      create: '/recipients/:recipientId/heart_measurements',
      update: '/recipients/:recipientId/heart_measurements/:id'
    },
    thoracic_measurements: {
      index: '/recipients/:recipientId/thoracic_measurements',
      create: '/recipients/:recipientId/thoracic_measurements',
      update: '/recipients/:recipientId/thoracic_measurements/:id'
    },
    heart_hemodynamic: {
      index: '/recipients/:recipientId/heart_hemodynamic',
      create: '/recipients/:recipientId/heart_hemodynamic',
      update: '/recipients/:recipientId/heart_hemodynamic/:id',
      new_validations: '/recipients/:recipientId/heart_hemodynamic/new',
      edit_validations: '/recipients/:recipientId/heart_hemodynamic/:id/edit'
    },
    procedure_vad: {
      index: '/recipients/:recipientId/vad_procedures',
      create: '/recipients/:recipientId/vad_procedures',
      update: '/recipients/:recipientId/vad_procedures/:id'
    },
    stemCellTherapy: {
      create: '/recipients/:recipientId/stem_cell_therapy_procedure',
      show: '/recipients/:recipientId/stem_cell_therapy_procedure',
      update: '/recipients/:recipientId/stem_cell_therapy_procedure',
    },
    procedure_pancreas_islets_infusions: {
      index: '/recipients/:recipientId/journeys/:journeyId/procedure_pancreas_islets_infusions',
      create: '/recipients/:recipientId/procedure_pancreas_islets_infusions',
      update: '/recipients/:recipientId/procedure_pancreas_islets_infusions/:id'
    },
    history_vxm: {
      index: '/recipients/:recipient_id/history_vxm',
      create: '/recipients/:recipient_id/history_vxm',
    },
    ctr_integration_log: {
      index: '/recipients/:id/ctr_integration_log'
    },
    outOfProvince: {
      index: '/oop_recipients?page_number=:page_number&page_size=:page_size:search_params',
      show: '/oop_recipients/:id',
      update: '/oop_recipients/:id',
      edit_validations: '/oop_recipients/:id/',
      journeys: {
        transplant: {
          update: '/oop_recipients/:recipientId/journeys/:journeyId/transplant',
          show: '/oop_recipients/:recipientId/journeys/:journeyId/transplant',
          delete: '/oop_recipients/:recipient_id/journeys/:journey_id/transplant',
        }
      }
    },
  },
  deceasedDonors: {
    index: '/donors?page_number=:page_number&page_size=:page_size:search_params',
    indexEXD: '/donors?page_number=:page_number&page_size=:page_size&results_reported=:results_reported:search_params&exceptional_distribution=:exceptional_distribution',
    active: '/donors/active?page_number=:page_number&page_size=:page_size:search_params',
    create: '/donors',
    show: '/donors/:id',
    update: '/donors/:id',
    organ_packaging_forms: '/donors/:id/organ_packaging_forms',
    hlaTyping: {
      create: '/donors/:donorId/hla_typing',
      show: '/donors/:donorId/hla_typing',
      update: '/donors/:donorId/hla_typing',
    },
    attachments: {
      create: '/donors/:donorId/attachments',
      show: '/donors/:donorId/attachments/:id',
      update: '/donors/:donorId/attachments/:id',
      index: '/donors/:donorId/attachments',
      download: '/services/ctr/donors/:id/download_attachments',
      upload: '/services/ctr/donors/:id/upload_attachments'
    },
    allocations: {
      all: '/donors/:donorId/allocations',
      index: '/donors/:donorId/organs/:organId/allocations/',
      create: '/donors/:donorId/organs/:organId/allocations',
      show: '/donors/:donorId/organs/:organId/allocations/:allocationId',
      show_details: '/allocations/:allocationId',
      rerun: '/donors/:donorId/organs/:organId/allocations/:allocationId/rerun',
      run_expedited: '/donors/:donorId/organs/:organId/allocations/run_expedited',
      rerun_expedited: '/donors/:donorId/organs/:organId/allocations/:allocationId/rerun_expedited',
      exclusion_rules: '/donors/:donor_id/organs/:organ_id/allocations/exclusion_rules',
      discontinue_offers: '/donors/:donorId/discontinue_offers',
      donor_details: '/donors/:donorId/organs/:organId/allocations/:allocationId/donor_details',
      recipient_details: '/donors/:donorId/organs/:organId/allocations/:allocationId/recipient_details',
      add_recipient: '/donors/:donorId/organs/:organId/allocations/:allocationId/add_recipient',
      opo_transplant_details: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/opo_transplant_details',
      // This has different casing than the others because of how the API returns endpoints. Trying to match this to what
      // API returns so that we can use the same constant.
      excluded_recipients: '/donors/:donor_id/organs/:organ_id/allocations/:id/excluded_recipients',
      offers: {
        // note the below index is just for checking access for use with SRC role, no functionality exists in it
        index: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers',
        create: '/donors/:donorId/organs/:organId/allocations/:allocationId/offers',
        no_offer: '/donors/:donorId/organs/:organId/allocations/:allocationId/offers/no_offers',
        show: '/donors/:donorId/organs/:organId/allocations/:allocationId/offers/:offerId',
        update: '/donors/:donorId/organs/:organId/allocations/:allocationId/offers/:offerId',
        discontinue_offers: '/donors/:donorId/organs/:organId/allocations/:allocationId/offers/discontinue_offers',
        discontinue_allocation: '/donors/:donorId/organs/:organId/allocations/:allocationId/discontinue_offers',
        respond: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers/respond'
      },
      checklist: {
        show: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/checklists',
        create: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/checklists',
        history: '/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/checklists_history'
      },
      postAllocation: '/donors/:donor_id/organs/:organ_id/allocations'
    },
    crossmatchLabs: {
      index: '/donors/:donorId/crossmatch_labs',
      create: '/donors/:donorId/crossmatch_labs',
      update: '/donors/:donorId/crossmatch_labs/:id',
      recipientSamples: {
        create: '/donors/:donorId/crossmatch_labs/:crossmatchLabId/recipient_samples',
        update: '/donors/:donorId/crossmatch_labs/:crossmatchLabId/recipient_samples/:id',
      }
    },
    exdConfirmations: {
      index: '/donors/:donor_id/exd_confirmations',
    },
    exdForms: {
      create: '/donors/:donor_id/print_exd_forms',
    },
    organDonations: {
      index: '/donors/:donor_id/organ_donations',
      ship: '/donors/:donor_id/organ_donations/:organ_donation_id/ship',
      organOffer: {
        confirmations: {
          create: '/donors/:donor_id/organ_donations/:organ_donation_id/organ_offer/exceptional_distribution/confirmations',
        },
      },
    },
    significantEvents: {
      index: '/donors/:donor_id/significant_events',
    },
    ctr_integration_log: {
      index: '/donors/:id/ctr_integration_log'
    },
  },
  virologies: {
    index: '/:view/:clientId/virologies',
    create: '/:view/:clientId/virologies',
    show: '/:view/:clientId/virologies/:id',
    update: '/:view/:clientId/virologies/:id',
  },
  lookups: {
    show: '/lookups/:id',
    recipient_journey_states: '/calculated_lookups/recipient_journey_states'
  },
  nowList: {
    index: '/services/ctr/now'
  },
  tools: {
    bmi: '/tools/bmi?height=:height&height_unit=:height_unit&weight=:weight&weight_unit=:weight_unit',
    cpra: '/tools/cpra?blood_type=:bloodType&:unacceptableAntigens',
    tlc: '/tools/tlc?age=:age&gender=:gender&height=:height'
  },
  laboratories: {
    index: '/laboratories/?lab_type=:lab_type',
  },
  hospitals: {
    index: '/hospitals?type=:hospital_type',
    show: '/hospitals/:id'
  },
  coordinators: {
    index: '/coordinators/?hospital_id=:hospital_id',
    index_living_donors: '/coordinators/living_donor_coordinators/?hospital_id=:hospital_id',
    show: "/coordinators/:id"
  },
  validations: {
    new_validations: '/:view/:action',
    edit_validations: '/:view/:id/:action',
    death_edit_validations: '/recipients/:recipient_id/death/edit',
  },
  reports: {
    allocation_report: '/reports/allocation_report',
    change_status_report: '/reports/change_status_report',
    crossmatch_report: '/reports/crossmatch_report',
    dialysis_report: '/reports/dialysis_report',
    tip_report: '/reports/transplant_in_progress_report',
    transplant_activity_report: '/reports/transplant_activity_report',
    waitlist_report: '/reports/:waitlist_report',
    organ_yield_report: '/reports/organ_yield_report',
    recipient_data_audit_detail: '/reports/recipient_data_audit_detail',
    recipient_data_audit_index: '/reports/recipient_data_audit_index'
  },
  fusionAuth: {
    request: '/auth/fusionauth',
    callback: '/auth/fusionauth/callback',
    access_token: '/access_token/fusionauth',
    user_select: '/user_select/sessions'
  },
  auth: {
    show: '/user',
    login: '/login',
    logout: '/logout',
    user_logins: '/user_select/users',
  },
  users: {
    index: '/admin/users?page_number=:page_number&page_size=:page_size:search_params',
    all: '/admin/users?all=true',
    create: '/admin/users',
    show: '/admin/users/:id',
    update: '/admin/users/:id',
    roles: '/admin/roles',
    preferences: '/user/preferences',
    profile: {
      show: '/user/profile',
      update: '/user/profile'
    }
  },
  administration: {
    ctr: {
      integration_log: {
        index: '/services/ctr/integration_log?page_number=:page_number',
      },
    },
  },
  waitlist: {
    grid: '/waitlist/:organCode/grid',
    print: '/waitlist/:organCode/print'
  },
  living_donors: {
    create: '/living_donors',
    show: '/living_donors/:id',
    update: '/living_donors/:id',
    index: '/living_donors?page_number=:page_number&page_size=:page_size:search_params',
    all: '/living_donors',
    measurements: {
      index: '/living_donors/:living_donor_id/measurements',
      create: '/living_donors/:living_donor_id/measurements',
      show: '/living_donors/:living_donor_id/measurements/:id',
      update: '/living_donors/:living_donor_id/measurements/:id',
    },
    hlaTyping: {
      create: '/living_donors/:living_donor_id/hla_typing',
      show: '/living_donors/:living_donor_id/hla_typing',
      update: '/living_donors/:living_donor_id/hla_typing',
    },
    recoveryInfo: {
      update: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/recovery_info',
      validate_save: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/recovery_info/validate_save',
      edit_validations: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/recovery_info/edit',
      transplant_complete: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/transplant_complete'
    },
    journeys: {
      update: '/living_donors/:living_donor_id/journeys/:id',
      intendedRecipients: {
        create: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/intended_recipients',
        update: '/living_donors/:living_donor_id/journeys/:living_donor_journey_id/intended_recipients/:intended_recipient_id',
      }
    },
    allocations: {
      all: '/living_donors/:living_donor_id/allocations',
      index: '/living_donors/:living_donor_id/organs/:organ_id/allocations/',
      create: '/living_donors/:living_donor_id/organs/:organ_id/allocations',
      show: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId',
      show_details: '/allocations/:allocationId',
      rerun: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/rerun',
      run_expedited: '/living_donors/:living_donor_id/organs/:organ_id/allocations/run_expedited',
      rerun_expedited: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/rerun_expedited',
      exclusion_rules: '/living_donors/:living_donor_id/organs/:organ_id/allocations/exclusion_rules',
      discontinue_offers: '/living_donors/:living_donor_id/discontinue_offers',
      donor_details: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/donor_details',
      recipient_details: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/recipient_details?recipient_id=:recipientId',
      add_recipient: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/add_recipient',
      // This has different casing than the others because of how the API returns endpoints. Trying to match this to what
      // API returns so that we can use the same constant.
      excluded_recipients: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:id/excluded_recipients',
      offers: {
        create: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers',
        no_offer: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers/no_offers',
        show: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers/:offerId',
        update: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers/:offerId',
        discontinue_offers: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers/discontinue_offers',
        discontinue_allocation: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/discontinue_offers',
        respond: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocationId/offers/respond'
      },
      checklist: {
        show: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocation_id/checklists',
        create: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocation_id/checklists',
        history: '/living_donors/:living_donor_id/organs/:organ_id/allocations/:allocation_id/checklists_history'
      },
    },
  },
  surgicalPeople: {
    index: '/surgical_people'
  },
  responsiblePhysicians: {
    index: '/responsible_physicians',
    filtered_index: '/responsible_physicians?hospital=:hospital&organ=:organ'
  },
  dashboard: {
    widgets: '/dashboard_widgets',
    active_allocations: '/dashboard/active_allocations',
    bookmarks: '/dashboard/bookmarks',
    recent_recipient_registrations: '/dashboard/recent_recipient_registrations?page_number=:page_number&page_size=:page_size:search_params',
    primary_offers: '/dashboard/active_primary_offers?type=:type',
    backup_offers: '/dashboard/active_backup_offers?type=:type',
    living_donor_registrations: '/dashboard/recent_living_donor_registrations?page_number=:page_number&page_size=:page_size:search_params',
    urgent_listings: '/dashboard/urgent_listings?page_number=:page_number&page_size=:page_size&urgent=:urgent&recent_only=:recent_only&:search_params',
    offers: '/dashboard/offers/?type=:type',
    notifications: '/dashboard/notifications',
    lab_results: '/dashboard/donor_lab_results',
    transplant_in_progress: '/dashboard/transplant_in_progress',
    exceptional_distribution_donors: '/dashboard/exd_donors?page_number=:page_number&page_size=:page_size&search_params=:search_params&exd_widget=:exceptional_distribution&results_reported=:results_reported'
  },
  loginProvider: {
    eHealth: '/auth/eHealth',
    azure: '/auth/azure_activedirectory_v2'
  },
  features: {
    index: '/features'
  },
  tasks: '/tasks/:taskId'
};

/**
 * constructs api route to point to (localhost:3000/api/v1/{string} or /api/v1/{string})
 *
 * @param endpoint path
 * @param replace parameters to replace in path
 * @returns {string}
 */
export function APIRoute(endpoint: string, replace?: Array<[string, string]>): string {
  let ep = `${process.env.VUE_APP_API_BASE_ENDPOINT}${endpoint}`;
  if (replace) {
    replace.forEach((r) => {
      ep = ep.replace(r[0], r[1]);
    });
  }
  return ep;
}

/**
 * constructs naked api route to point to (localhost:3000/{string} or /{string})
 *
 * @param endpoint path
 * @param replace parameters to replace in path
 * @returns {string}
 */
export function APINakedRoute(endpoint: string, replace?: Array<[string, string]>): string {
  const api_endpoint = process.env.VUE_APP_API_BASE_ENDPOINT;
  const api_naked_endpoint = api_endpoint.replace('/api/v1', '');
  let ep = `${api_naked_endpoint}${endpoint}`;
  if (replace) {
    replace.forEach((r) => {
      ep = ep.replace(r[0], r[1]);
    });
  }
  return ep;
}

/**
 * constructs api route to point to (localhost:8080/api/v1/{string} or /api/v1/{string})
 *
 * @param endpoint path
 * @param replace parameters to replace in path
 * @returns {string}
 */
export function APIBaseRoute(endpoint: string, replace?: Array<[string, string]>): string {
  const root = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_LOCALHOST : '';
  let ep = `${root}${endpoint}`;
  if (replace) {
    replace.forEach((r) => {
      ep = ep.replace(r[0], r[1]);
    });
  }
  return ep;
}
