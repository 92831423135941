
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import { NumericCodeValue } from '@/store/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipient, RecipientOopTransplant } from '@/store/recipients/types';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import HlaInput from '@/components/shared/HlaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { AssociatedJourney, LivingAllocationRecipient, LivingDonorDetails, LivingRecipientDetails, MELD_MEDICAL_STATUS, SMC_MEDICAL_STATUS } from '@/store/livingAllocations/types';
import { Organ, OrganWaitlistMedicalStatus, OrganDiseaseCode, OrganCodeValue, SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS } from '@/store/lookups/types';
import { HlaTypingTag, HlaSerologicalValue, LabHlaTypingEpitope } from '@/store/labs/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import {AllocationUtilsMixin} from "@/mixins/allocation-utils-mixin";
import { isMasked } from '@/utils';
import RecipientLink from '@/components/shared/RecipientLink.vue';
import HlaVirtualCrossmatchResult from '@/components/hla/HlaVirtualCrossmatchResult.vue';

interface HlaTypingAntigenRow {
  locus?: string,
  molecular?: string[],
  most_likely_allele?: string[],
  serologic?: string[]
}

interface HlaTypingLoci {
  locus?: string,
  sequence?: number
}

@Component({
  components: {
    SubSection,
    ModalSection,
    HlaInput,
    HlaInputGroup,
    VueGoodTable,
    OrganIcon,
    RecipientLink,
    HlaVirtualCrossmatchResult,
  }
})
export default class CompareModal extends mixins(DateUtilsMixin, AllocationUtilsMixin) {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.donorDetails) private donorDetails!: LivingDonorDetails;
  @State(state => state.livingAllocations.recipientDetails) private recipientDetails!: LivingRecipientDetails;
  @State(state => state.lookups.organ) organLookup!: Organ[];

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: any;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('parseHlaTypingTag', { namespace: 'labs' }) parseHlaTypingTag!: (tagText: string) => HlaTypingTag|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('allDonorTypes', { namespace: 'lookups' }) donorTypes!: any;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;

  // Order of molecular locus values shown in HLA Typing Details Table
  private CLASS_1_TYPING_TABLE: HlaTypingLoci[] = [
    { locus: 'A', sequence: 1 },
    { locus: 'A', sequence: 2 },
    { locus: 'B', sequence: 1 },
    { locus: 'B', sequence: 2 },
    { locus: this.epitopesLabel },
    { locus: 'C', sequence: 1 },
    { locus: 'C', sequence: 2 },
  ];

  // Order of molecular locus values shown in HLA Typing Details Table
  private CLASS_2_TYPING_TABLE: HlaTypingLoci[] = [
    { locus: 'DRB1', sequence: 1 },
    { locus: 'DRB1', sequence: 2 },
    { locus: 'DRB3', sequence: 1 },
    { locus: 'DRB3', sequence: 2 },
    { locus: 'DRB4', sequence: 1 },
    { locus: 'DRB4', sequence: 2 },
    { locus: 'DRB5', sequence: 1 },
    { locus: 'DRB5', sequence: 2 },
    { locus: 'DQB1', sequence: 1 },
    { locus: 'DQB1', sequence: 2 },
    { locus: 'DQA1', sequence: 1 },
    { locus: 'DQA1', sequence: 2 },
    { locus: 'DPB1', sequence: 1 },
    { locus: 'DPB1', sequence: 2 },
    { locus: 'DPA1', sequence: 1 },
    { locus: 'DPA1', sequence: 2 }
  ];

  private loading = true;
  private recipientDetailsErrorMessage = '';

  // load donor details on mount
  public mounted() {
    this.$store.dispatch('livingAllocations/getDonorDetails', { livingDonorId: this.clientId, organCode: this.allocation.organ_code, allocationId: this.allocation._id });
  }

  // Load up the recipient details and catch any errors
  public initializeAllocationCompare(recipientId: any, rank?: number): void {
    this.loading = true;
    // clear error message
    this.recipientDetailsErrorMessage = "";
    const recipient = this.allocation?.recipients ? this.allocation.recipients.find((r: LivingAllocationRecipient) => {
      return r._id === recipientId && r.rank === rank
    }) : null;
    this.toggleModal();
    this.$store.dispatch('livingAllocations/getRecipientDetails', { 
      clientId: this.clientId,
      organCode: this.allocation.organ_code,
      allocationId: this.allocation._id,
      is2ndRankingEntry: recipient ? recipient.is_2nd_ranking_entry : false,
      recipientId: recipientId
    }).then(() => {
      if(!this.recipientDetails.recipient.donor_acceptability) {
        // Force set a blank donor_acceptability for OOP manually added recipient
        this.$set(this.recipientDetails.recipient, 'donor_acceptability', {});
      }
      this.loading = false;
    }).catch((error: any) => {
      this.recipientDetailsErrorMessage = error.errorMessages && error.errorMessages.length > 0 ? error.errorMessages[0] : error;
      this.loading = false;
    });
  }

  // Clear recipient details state and reset the tabs when the modal is closed
  private clearRecipientDetailsState() {
    this.$store.commit('livingAllocations/clearRecipientDetails');
    // reset tabs
    if (this.recipientDetails) {
      const recipientDetailsTab = this.$refs.recipientDetailsTab as HTMLElement;
      recipientDetailsTab.click();
    }
  }

  private checkIfOrganSpecificAllocation(organ: any) {
    if (this.recipientDetails.organ == organ) {
        return true;
    }
  }

  // Toggle modal
  private toggleModal(): void {
    const targetModal = this.$refs.recipientInformationModal as ModalSection;
    targetModal.toggleModal();
  }

  public parseDate(datetime: string) {
    return !isMasked(datetime) ? this.parseFormattedDateUi(datetime) : datetime;
  }

  // if value is null then return '-' otherwise return value
  public parseNullValue(value: any) {
    return value === null ? '-' : value ? this.$t('yes').toString() : this.$t('no').toString();
  }

  // if value is 0 then returns 0, if value is null the returns '-', otherwise returns value
  public parsePossibleZeroValue(value: number) {
    return value === 0 ? 0 : (value || '-');
  }

  /**
   * @param bmi number value of bmi
   * @returns {number} bmi to 1 decimal place
   */
  public parseBmi(bmi: number): number|string {
    return bmi ? parseFloat(bmi.toFixed(1)) : '-';
  }

  /**
   * @param height number value of height
   * @returns {string} height with cm unit measurement
   */
  public parseHeight(height: number): string {
    return height ? height + 'cm' : '-';
  }

  /**
   * @param weight number value of bmi
   * @returns {string} weight to 1 decimal place with kg unit measurement
   */
  public parseWeight(weight: number): string {
    // Handle missing information: this is true only for [null, undefined]; false for 0
    return weight ? parseFloat(weight.toFixed(1))  + 'kg' : '-';
  }

  /**
   * List of Exceptional Distribution reason text values based on exd_reason_codes
   * in donor details response from allocation service.
   *
   * Note: does not include 'other travel', 'other transmission', or 'other other',
   * since those are disabled as separate rows in the compare modal
   *
   * @returns {string[]} array of exd reasons
   */
  get exdReasonValues(): string[] {
    if (!this.donorDetails || !this.donorDetails.donor) return [];

    // get list of exd reason codes
    const codes = this.donorDetails?.donor?.exd_reason_codes || [];

    // filter out system-only reasons (see B#15171)
    const filtered = codes.filter((code: number) => {
      return !SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS.includes(code);
    });

    // Sort codes by number and then map to display text
    const values = filtered.sort((x: any, y: any) => x > y ? 1 : -1).map((code: number): string => {
      return this.lookupValueNumeric(code, 'donor_exceptional_distribution') || 'Unknown';
    });
    return values;
  }

  // Whether or not the selected organ allocation is for lung
  get isLungAllocation() {
    const organCode = this.allocation.organ_code;
    return organCode == OrganCodeValue.Lung;
  }

  /**
   * Returns name of selected organ, based on allocation organ code
   *
   * @returns {string} organ name
   */
  get allocationOrganName() {
    const organCode = this.allocation.organ_code;
    const allocationOrganName: string|undefined = this.organNameLookup(organCode) || undefined;
    return allocationOrganName ? this.$t(allocationOrganName) : 'Unknown';
  }

  get classOneTypingTable() {
    return this.CLASS_1_TYPING_TABLE;
  }

  get classTwoTypingTable() {
    return this.CLASS_2_TYPING_TABLE;
  }

  get epitopesLabel() {
    return 'Epitopes';
  }

  // Whether or not we have enough information to navigate to Recipient profile and Recipient HLA pages
  get showRecipientLinks(): boolean {
    return !!this.recipientDetails && !!this.recipientDetails?.recipient?.client_id && !isMasked(this.recipientDetails?.recipient?.client_id);
  }

  // Get the recipient's transplant program
  get getRecipientTransplantProgram(): string|null {
    // if no hospital id, fallback to show program code
    if (!this.recipientDetails?.recipient?.hospital_id) return this.recipientDetails?.recipient?.program;
    const transplantId = this.recipientDetails?.recipient?.hospital_id ? this.recipientDetails.recipient.hospital_id as unknown as string : null;
    const transplantProgram = transplantId ? this.getHospitalAbbreviation(transplantId) : '--';
    return transplantProgram;
  }

  /**
   * Provide a string representation of the recipient's required organ
   *
   * Since the recipient can be listed for combinations of organs, we might be showing a list of organ names.
   * Ideally we show the 'waitlisted_for_organs' string array derived by the Allocation Service. If this is
   * not available (e.g. it appears to be empty for CTR Recipients) we can instead use the 'organ' property
   * that relates to the organ the allocation was originally run for
   *
   * As per CR-2023-019, new string array derived from Allocation Service 'listed_for' is used to show sorted organs.
   * 
   * @returns {string} text description of the recipient's Waitlisted Organ Journeys
   */

  get describeOrganListedFor(): string {
    if (!this.recipientDetails || !this.recipientDetails.recipient) {
      return '-';
    }
    const waitlistedOrgans = this.recipientDetails.recipient?.listed_for || [this.recipientDetails.organ];
    return this.parseListedFor(this.recipientDetails.recipient.registration_type, waitlistedOrgans);
  }

  /**
   * Get a string representation of the recipient's donor type
   *
   * @returns {string} string representation of the recipient's preferred donor type
   */
  get recipientDonorType(): string {
    const donorAcceptability = this.recipientDetails.recipient.donor_acceptability;
    if(!donorAcceptability) return 'N/A';
    const donorType = this.donorTypes.find((e: any) => {
      return e.deceased_donor == donorAcceptability.deceased_donor && e.living_donor == donorAcceptability.living_donor;
    });
    return donorType ? donorType.value : 'N/A';
  }

  /**
   * TPGLI-8025. Changed Acronym from SMC to MELD. Since the lookup code can't be updated due 
   * possible regression issues on API + CTR sync, this method replaces the SMC for MELD
   *
   * @returns {string} string option for the medical status dropdown.
   */
  public displayMedicalStatus(organ: AssociatedJourney): string {
    const medicalStatusCode = organ.medical_status_code === SMC_MEDICAL_STATUS ? MELD_MEDICAL_STATUS : organ.medical_status_code;
    return `${medicalStatusCode} - ${organ.medical_status_desc}`;
  }

  public displayAllocationStep(allocationStep: string): string {
    return allocationStep.replaceAll(SMC_MEDICAL_STATUS, MELD_MEDICAL_STATUS);
  }

  public sexValue(code: string):string {
    return code ? this.lookupValue(code, 'gender') : '-';
  }

  // String representation of the Medical Status using the Medical Status Lookup
  private parseMedicalStatus(medicalStatusCode: any): string {
    const medStatusLookup = this.medicalStatusLookup(true, this.organLookup, this.allocation.organ_code);
    const medicalStatus = medStatusLookup.find((medStatus: OrganWaitlistMedicalStatus) => medStatus.code == medicalStatusCode);
    return medicalStatus?.value ? medicalStatus?.value.replaceAll(SMC_MEDICAL_STATUS, MELD_MEDICAL_STATUS) : null;
  }

  // String representation of the Secondary Medical Status using the Secondary Medical Status Lookup
  private parseSecondaryMedicalStatus(secondaryMedicalStatusCode: any): string|undefined {
    if (secondaryMedicalStatusCode) {
      const secondaryMedStatusLookup = this.secondaryMedicalStatusLookup(this.organLookup, this.allocation.organ_code);
      const secondaryMedicalStatus = secondaryMedStatusLookup.find((secondaryMedStatus: OrganWaitlistMedicalStatus) => secondaryMedStatus.code == secondaryMedicalStatusCode);
      return secondaryMedicalStatus!.value;
    } else {
      return undefined;
    }
  }

  public parseVirology(value: boolean) {
    if (value == null) {
      return 'Not Done';
    } else if (!value) {
      return 'Negative';
    } else {
      return 'Positive';
    }
  }

  /**
   * Gets table row data representing the HLA Typing for the selected recipient.
   *
   * @param formState current edit state for the HLA Typing form
   * @returns {HlaTypingAntigenRow[]} HLA Typing rows
   */
  public hlaTypingDetailRows(CLASS_TYPING: any, patient: any): HlaTypingAntigenRow[] {
    const result: HlaTypingAntigenRow[] = [];

    // if typing isn't available then return empty array
    if(!patient.hla_typing.typing_available) {
      return result;
    }

    const antigens = patient.hla_typing.antigens;
    let prevLocus = "";

    // Iterate through all possible loci
    antigens.map((item: any) => {
      item.sequence = prevLocus === item.molecular_locus ? 2 : 1;
      prevLocus = item.molecular_locus;
    });

    CLASS_TYPING.forEach((loci: HlaTypingLoci, index: number) => {
      const antigen = antigens.find((item: any) => item.molecular_locus === loci.locus && item.sequence === loci.sequence);

      // Epitopes
      if (loci.locus == this.epitopesLabel) {
        let epitopes: string[] = [];

        // loop through epitopes and push the values into the array
        patient.hla_typing.epitopes.forEach((epitope: LabHlaTypingEpitope) => {
          if (epitope.epitope_value){
            epitopes.push(epitope.epitope_value);
          }
        });

        let sequence1: HlaTypingAntigenRow = {
          locus: loci.locus,
          molecular: epitopes
        };
        // push the epitopes into the HLA Typing Array
        result.push(sequence1);
      }
      if (antigen) {
        let molecular = this.parseMolecularValue(antigen.molecular_value);
        let most_likely_allele = this.parseMostLikelyAllele(antigen.molecular_value);
        let serologic = antigen.serological_values;

        let sequence1: HlaTypingAntigenRow = {
          locus: loci.locus,
          molecular: molecular ? [molecular] : [],
          most_likely_allele: most_likely_allele ? [most_likely_allele] : [],
          serologic: serologic ? serologic : [],
        };
        // Store the entries for this locus
        result.push(sequence1);
      }
    });
    return result;
  }

  /**
   * Gets configuration for the Details Table
   *
   * @returns {TableConfig} Details Table configuration
   */
  public hlaTypingDetailsTableConfig(isRecipient: boolean, class_typing: any): TableConfig {
    return {
      data: isRecipient ? this.hlaTypingDetailRows(class_typing, this.recipientDetails.recipient) : this.hlaTypingDetailRows(class_typing, this.donorDetails.donor),
      columns: [
        { label: '', field: 'locus', width: '5%' },
        { label: this.$t('molecular').toString(), field: 'molecular', width: '27.5%' },
        { label: this.$t('most_likely_allele').toString(), field: 'most_likely_allele', width: '40%' },
        { label: this.$t('serologic').toString(), field: 'serologic', width: '27.5%' },
      ],
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  /**
   * Extract the gene from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01" if the input is "01:02"
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMolecularValue(rawMolecular?: string|null): string|undefined {
    if (!rawMolecular) {
      return undefined;
    }
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return rawMolecular;
    }
    return antigen.standardAlleleGroupOnly;
  }

  /**
   * Extract just the allele from an antibody string that may have allele-specific information.
   *
   * E.g. returns "01:02" if the input is "01:02", but undefined for "01" on its own
   *
   * @param rawMolecular string representation of an antibody that may or may not have allele-specific information
   */
  private parseMostLikelyAllele(rawMolecular?: string|null): string|undefined {
    if (!rawMolecular) {
      return undefined;
    }
    const antigen = this.parseHlaTypingTag(rawMolecular);
    if (!antigen) {
      return undefined;
    }
    return antigen.standardMostLikelyAllele || undefined;
  }

}
